import * as React from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import { Box, Paper, Grid, Container, TextField, Link, Button } from '@mui/material';
import axios from 'axios';
import { apiBaseUrl, LogoImg, LoginBg } from "./components/generale";
import { Login, ArrowForwardIos } from '@mui/icons-material';

const MyLoginPage = ({ theme }) => {
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [loginOrReset, setLoginOrReset] = React.useState(true);
    const login = useLogin();
    const notify = useNotify();
    // const logoImageUrl = require(process.env.PUBLIC_URL+'images/logo.png');

    const handleLogin = e => {
        e.preventDefault();
        login({ username: email, password }).catch(() =>
            notify('Le credenziali inserite non sono corrette. Verifica e riprova.', { type: 'error' })
        );
    };

    const handleRecuperaPassword = e => {
        e.preventDefault();
        var bodyFormData = new FormData();
        bodyFormData.append('email', e.target.username.value);
        bodyFormData.append('refer', (window.location.hostname === "localhost" ? "-test" : ""));

        axios.post(apiBaseUrl + '/operation/reset-password.php', bodyFormData)
            .then(response => {
                notify(`E' stata inviata una richiesta di reset password. Riceverai al tuo indirizzo email ${e.target.username.value} un link per procedere con il cambio della password.`, { type: 'success' });
            });
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', minHeight: "100vh", backgroundImage: `url(${LoginBg})`, backgroundSize: 'cover', backgroundPosition: 'center bottom' }}>
            <Paper sx={{ py: 3, mx: 2 }}>
                <Box
                    component="img"
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mx: 'auto', width: 'auto', maxWidth: "100%", mb: 2 }}
                    src={LogoImg}
                />
                <Container maxWidth="sm">

                    {/* Mostra il modulo di Login */}
                    {loginOrReset === true ?
                        <>
                            <form onSubmit={handleLogin}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            id="username"
                                            label="E-Mail"
                                            variant="filled"
                                            type="email"
                                            fullWidth
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            id="password"
                                            label="Password"
                                            variant="filled"
                                            type="password"
                                            fullWidth
                                            onChange={e => setPassword(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <Button type='submit' variant='contained' color='primary' startIcon={<Login />} label='Accedi'>Accedi</Button>
                                    </Grid>
                                </Grid>
                            </form>
                            <Grid container spacing={2} sx={{ mt: 3 }}>
                                <Grid item xs={12} md={12}>
                                    <Link underline="hover" size="small" onClick={() => { setLoginOrReset(!loginOrReset) }}><small>Hai dimenticato la password?</small></Link>
                                </Grid>
                            </Grid>
                        </>
                        : null}

                    {/* Mostra il modulo di Reset Password */}
                    {loginOrReset === false ?
                        <>
                            <form onSubmit={handleRecuperaPassword}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            id="username"
                                            label="E-Mail"
                                            variant="filled"
                                            type="email"
                                            fullWidth
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        {/* <Button type='submit' variant='contained' color='primary' size='large' label="Reset password" /> */}
                                        <Button type='submit' variant='contained' color='primary' endIcon={<ArrowForwardIos />}>Reset password</Button>
                                    </Grid>
                                </Grid>
                            </form>
                            <Grid container spacing={2} sx={{ mt: 3 }}>
                                <Grid item xs={12} md={12}>
                                    <Link underline="hover" onClick={() => { setLoginOrReset(!loginOrReset) }}><small>Torna al login</small></Link>
                                </Grid>
                            </Grid>
                        </>
                        : null}
                </Container>
            </Paper>
        </Box>
    );
};

export default MyLoginPage;