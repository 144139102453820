import * as React from "react";
import { List, ListBase, Datagrid, TextField, ReferenceField, FunctionField, usePermissions, useRecordContext } from 'react-admin';
import { useMediaQuery } from '@mui/material';
import { EmptyPage } from '../EmptyPage';
import { RuotaDevice } from '../RuotaDevice';
import { LoadingComponent } from '../LoadingComponent';
import { ButtonPagato } from './BottonePagamento';
var numeral = require('numeral');


export const LeadDetails = () => {
    const record = useRecordContext();
    if (!record) return <LoadingComponent />;
    // console.log(record);
    return (
        <>
            <p><b>Dettagli del segnalatore</b></p>
            <ListBase disableSyncWithLocation resource="utenti" filter={{ id: record.id_utente }} exporter={false} perPage={100} sort={{ field: 'id', order: 'DESC' }} pagination={false} sx={{ mt: '-60px', mb: '40px' }}>
                <Datagrid bulkActionButtons={false} sx={{ backgroundColor: '#f1f1f1' }}>
                    <FunctionField sortable={false} render={record => (record.rag_soc !== '' && record.rag_soc !== null ? record.rag_soc : record.nominativo)} label="Nominativo" />
                    <FunctionField sortable={false} render={record => (`${record.indirizzo}, ${record.n_civico} - ${record.cap} ${record.comune} (${record.provincia})`)} label="Indirizzo" />
                    <TextField source="cod_fisc" label="Cod. Fisc." />
                    {(record.p_iva !== '' && record.p_iva !== null
                        ? <TextField source="p_iva" label="P.IVA" />
                        : null)}
                    {(record.sdi !== '' && record.sdi !== null
                        ? <TextField source="sdi" label="SDI" />
                        : null)}
                    <TextField source="telefono" />
                    <TextField source="email" />
                </Datagrid>
            </ListBase>

            <p><b>Leads inclusi nel pagamento</b></p>
            <ListBase disableSyncWithLocation resource="leads_pagabili_dettaglio" filter={{ id_utente: record.id_utente }} exporter={false} perPage={100} sort={{ field: 'id', order: 'DESC' }} pagination={false} sx={{ mt: '-60px', mb: '40px' }}>
                <Datagrid bulkActionButtons={false} sx={{ backgroundColor: '#f1f1f1' }}>
                    <TextField source="id" />
                    <ReferenceField source="id" reference="leads" label="Nominativo" link={false} sortable={false}>
                        <TextField source="nominativo" />
                    </ReferenceField>
                    <FunctionField sortable={false} render={record => (`${numeral(parseFloat(record.valore)).value()} €`)} label="Valore" />
                </Datagrid>
            </ListBase>
        </>
    );
}

export const ListaLeadsPagabili = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();
    const [nominativoSegnalatore, setNominativoSegnalatore] = React.useState(null);
    const record = useRecordContext();

    React.useEffect(() => {
        document.title = "Lista dei Leads pagabili";
    }, []);


    const CustomTextField = () => {
        const record = useRecordContext();
        if (record)
        setNominativoSegnalatore(record.nominativo);
        return record.nominativo
    }

    return (
        <>
            <RuotaDevice />
            <List sort={{ field: 'id', order: 'DESC' }} perPage={50} empty={<EmptyPage testo='leads pagabili' />} exporter={false}>
                <Datagrid bulkActionButtons={false} expand={<LeadDetails />}>
                    <TextField source="id" label="ID utente" />
                    {/* <ReferenceField source="id_utente" reference="utenti" label="Segnalatore" link={false}>
                        <FunctionField render={record => setNominativoSegnalatore(record.nominativo)} />
                    </ReferenceField> */}
                    <ReferenceField source="id_utente" reference="utenti" label="Nominativo segnalatore" link={false}>
                        <TextField source="nominativo" />
                    </ReferenceField>
                    <ReferenceField source="id_utente" reference="utenti" label="Iban" link={false}>
                        <TextField source="iban" />
                    </ReferenceField>
                    <FunctionField sortable={false} render={record => (`${numeral(parseFloat(record.totale)).value()} €`)} label="Totale" />
                    {permissions === '5' ?
                        // <FunctionField sortable={false} render={record => (<ButtonPagato id_utente={record.id_utente} nominativo={false} />)} />
                        <ReferenceField source="id_utente" reference="utenti" label="Segnalatore" link={false}>
                            <FunctionField sortable={false} render={record => (<ButtonPagato utente={record} />)} />
                        </ReferenceField>
                        : null}
                </Datagrid>
            </List>
        </>
    )
};