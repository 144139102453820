import * as React from "react";
import { Button, SimpleForm, regex, useNotify, useRedirect, useListContext, useUpdate } from 'react-admin';
import axios from 'axios';
import { Box, Paper, Grid, Container, TextField, Alert, AlertTitle } from '@mui/material';
import { useSearchParams } from "react-router-dom";
import { apiBaseUrl, LogoImg, LoginBg, verificaPWDpattern } from "../generale";

const ProfiloEdit = ({ staticContext, ...props }) => {
    const redirect = useRedirect();
    const notify = useNotify();
    // const [update, { isLoading, error }] = useUpdate();
    // const { data, selectedIds } = useListContext();
    const [password, setPassword] = React.useState('');
    const [passwordValida, setPasswordValida] = React.useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const countParams = Array.from(searchParams).length;

    const handleResetPassword = e => {
        e.preventDefault();
        var bodyFormData = new FormData();
        bodyFormData.append('email', searchParams.get("e"));
        bodyFormData.append('reset_code', searchParams.get("c"));
        bodyFormData.append('pwd', e.target.password.value);
        bodyFormData.append('refer', (window.location.hostname === "localhost" ? "-test" : ""));

        axios.post(apiBaseUrl + '/operation/reset-password.php', bodyFormData)
            .then(response => {
                if (response.data === 'ok') {
                    notify(`La password è stata modificata con successo. Puoi ora accedere utilizzando le nuove credenziali.`, { type: 'success' });
                    redirect('/login');
                } else {
                    notify(`Non è stato possibile procedere con la modifica della password. Riprova più tardi o ricomincia la procedura di reimpostazione dall'inizio.`, { type: 'error' });
                }
            });
    };

    const validazionePWD = (pwd) => {
        // console.log(pwd);
        const reg = new RegExp(verificaPWDpattern);
        if (reg.test(pwd)) {
            setPassword(pwd);
            setPasswordValida(true);
        } else {
            // setPassword('');
            setPasswordValida(false);
        }
    };

    // console.log(countParams);
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', minHeight: "100vh", backgroundImage: `url(${LoginBg})`, backgroundSize: 'cover', backgroundPosition: 'center bottom' }}>
            <Paper sx={{ py: 3, mx: 2 }}>
                <Box
                    component="img"
                    sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mx: 'auto', width: 'auto', maxWidth: "100%", mb: 2 }}
                    src={LogoImg}
                />
                <Container maxWidth="sm">

                    {(countParams > 0 ?
                        <form onSubmit={handleResetPassword}>
                            {/* Mostra il modulo di Reset Password */}
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        id="password"
                                        label="Nuova password"
                                        variant="filled"
                                        type="password"
                                        fullWidth
                                        error={!passwordValida}
                                        helperText={!passwordValida ? <Alert severity="warning" sx={{ mt: 2 }}>Inserisci una password valida:
                                            <ul>
                                                <li>almeno 12 caratteri</li>
                                                <li>caratteri maiuscoli</li>
                                                <li>caratteri minuscoli</li>
                                                <li>numeri</li>
                                                <li>caratteri speciali ammessi (#@?!$_)</li>
                                            </ul></Alert>
                                            : 
                                            <Alert severity="success" sx={{ mt: 2 }}>La password inserita è formalmente corretta.</Alert>
                                            }
                                        onChange={e => validazionePWD(e.target.value)}
                                    />
                                    <TextField id="email" type="hidden" name="email" />
                                    <TextField id="reset_code" type="hidden" name="reset_code" />
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Button type='submit' variant='contained' color='primary' size='large' label="Reset password" />
                                </Grid>
                            </Grid>
                        </form>
                        :
                        <Alert severity="error" sx={{ my: "20px" }}>
                            <AlertTitle><b>Sembrano ci siano dei problemi</b></AlertTitle>
                            <p>La richiesta sembra errata o incompleta. Verifica nuovamente il link ricevuto via email e riprova.</p>
                            <p>Se il problema dovesse persistere ti invitiamo a contattare il nostro servizio clienti.</p>
                        </Alert>
                    )}
                </Container>
            </Paper>
        </Box>
    );
};

export default ProfiloEdit;