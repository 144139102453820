import * as React from "react";
import { Button, useRecordContext, Confirm, useUpdate, useGetList, useCreate, useNotify, useRefresh, useDataProvider } from 'react-admin';
import { Paid } from '@mui/icons-material';
import moment from 'moment';
var numeral = require('numeral');

export const ButtonPagato = ({ utente }) => {
    const dataProvider = useDataProvider();
    const [statoModalPagato, setStatoModalPagato] = React.useState(false);
    const [idUtenteLeadsPagati, setIdUtenteLeadsPagati] = React.useState(null);
    const record = useRecordContext();
    const id_operatore = localStorage.getItem('auth_uid');

    const { data: listaLeads, isLoading: loadingListaLeads, error: errorListaLeads } = useGetList(
        'leads_pagabili_dettaglio',
        { filter: { id_utente: utente.id } }
    );
    const notify = useNotify();
    const refresh = useRefresh();

    const ModificaLeadsPagati = async () => {
        const dataOra = moment().format("YYYY-MM-DD HH:mm:ss");
        // console.log(id_utente);
        // console.log(listaLeads);

        if (loadingListaLeads) { return <p />; }
        if (errorListaLeads) { return <p>ERROR</p>; }
        if (listaLeads.length <= 0) { return <p>ERROR</p>; }
        const leadsIDS = listaLeads.map(l => l.id);
        // console.log("ID dei leads", leadsIDS);

        await Promise.all(leadsIDS.map(async (value, index) => {
            const { data: lms } = await dataProvider.create('leads_movimenti_stati', {
                data: {
                    id_lead: value,
                    id_stato: 5,
                    id_operatore: id_operatore,
                    data_ora: dataOra
                }
            });
            // console.log(lms.id);
            const { data: updateData } = await dataProvider.update('leads', {
                id: value,
                data: { id_movimento_stato: lms.id }
            });
            return updateData;
        }))
        .catch(() => {
            notify('Si è verificato un errore. Verificare che il dispositivio sia connesso a internet e riprovare.', { type: 'warning' });
            refresh();
        })
        .then((res) => {
            // console.log(res);
            if (res !== null && res !== undefined) {
                res.forEach(r => {
                    if (r.id === null || r.id === undefined || r.id <= 0) {
                        notify('Sembra esserci stato un problema. Aggiorna la pagina e verifica se le modifiche richieste sono state completate, in caso contrario contatta l\'assistenza.', { type: 'warning' });
                        refresh();
                        setStatoModalPagato(false);
                        return;
                    }
                });
                notify(`Operazione completata`, { type: 'success' });
                refresh();
                setStatoModalPagato(false);
            } else {
                notify('Sembra esserci stato un problema. Aggiorna la pagina e verifica se le modifiche richieste sono state completate, in caso contrario contatta l\'assistenza.', { type: 'warning' });
                refresh();
                setStatoModalPagato(false);

            }
        });

    }

    if (!record) return null;

    return (
        <>
            <Button label="Segna come pagato" onClick={() => { setStatoModalPagato(true); setIdUtenteLeadsPagati(record.id_utente); /*console.log(record.id_utente);*/ }} variant="outlined"><Paid /></Button>
            <Confirm
                key={record.id_utente}
                isOpen={statoModalPagato}
                title={`Segnare come Pagato i leads del segnalatore ${record.nominativo}?`}
                content="Procedendo verranno contrassegnati come pagati tutti i relativi leads"
                onConfirm={ModificaLeadsPagati}
                onClose={() => setStatoModalPagato(false)}
            />
        </>
    );
};