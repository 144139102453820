import * as React from "react";
import { Create, SimpleForm, TextInput, required, email, SelectInput, CheckboxGroupInput, Button, useCreate, useUpdate, useNotify, useRedirect } from 'react-admin';
import { Grid, Alert, AlertTitle, Typography } from '@mui/material';
import { ListaProvince, verificaCF, apiBaseUrl } from '../generale';
import moment from 'moment';
import axios from 'axios';

export const LeadNuovo = () => {

    const [create, { isLoading, error }] = useCreate();
    const [update] = useUpdate();
    const redirect = useRedirect();
    const notify = useNotify();

    const idUtente = localStorage.getItem('auth_uid');

    React.useEffect(() => {
        document.title = "Inserisci un nuovo Lead";
    }, []);

    const onSuccess = async ({ id }) => {
        const idLead = id;
        let dataOra = moment().format("YYYY-MM-DD HH:mm:ss");
        await create('leads_movimenti_stati', {
            data: {
                id_lead: idLead,
                id_stato: 1,
                id_operatore: idUtente,
                data_ora: dataOra
            }
        }, {
            onSuccess: async ({ id }) => {
                let id_ms = { id_movimento_stato: id };
                // console.log(idLead, id_ms);
                await update(
                    'leads',
                    {
                        id: idLead,
                        data: id_ms
                    }, {
                    onSuccess: () => {
                        notify("Lead creato", { type: 'success' });
                        var bodyFormData = new FormData();
                        bodyFormData.append('id', idLead);
                        bodyFormData.append('refer', (window.location.hostname === "localhost" ? "-test" : ""));

                        axios.post(apiBaseUrl + '/operation/nuovo-lead.php', bodyFormData)
                        .then(response => {
                            console.log(response);
                        });
                        redirect("/leads");
                    },
                    onError: (error) => {
                        notify("Si è verificato un errore con l'inserimento. Riprova più tardi. Se il problema persiste contatta la nostra Assistenza Tecnica.", { type: 'warning' });
                        redirect("/leads");
                    }
                }
                )
            }
        });
    }

    const privacyCheck = [
        { id: 1, link: process.env.PUBLIC_URL + 'informativa_privacy.pdf', testoLink: "Leggi l'informativa", sourceName: 'privacy_1', testo: 'Prendo visione e accetto l’informativa' },
        { id: 2, link: null, testoLink: null, sourceName: 'privacy_2', testo: 'Acconsento al trattamento dei miei dati personali per l’invio, da parte di solaraccess.it , di comunicazioni promozionali relative a prodotti e/o servizi di solaraccess.it o di società ad esso collegate, con l’uso del telefono con operatore e/o anche mediante sistemi automatizzati (es. email, sms).' },
        { id: 3, link: process.env.PUBLIC_URL + 'lista_completa_terzi.pdf', testoLink: "Visualizza la lista completa", sourceName: 'privacy_3', testo: 'Acconsento la cessione per scopi di marketing e commerciali, con l’uso del telefono con operatore e/o con sistemi automatizzati (es. email, sms) e/o invio di materiale promozionale a mezzo posta, a terzi soggetti facenti parte delle seguenti categorie economiche o merceologiche: Turistico, Tempo libero, High Tech, Moda, Arredamento, Largo Consumo, Food & Beverage, Finanza, Banche, Assicurazioni, Energia, Ambiente, Comunicazione, Media, Entertainment, Real Estate, Farmaceutico, Automobili, Abbigliamento e tessile, Formazione, Energia, Editoria, ICT, Retail, Sport, Telecomunicazioni, e Servizi in generale.' },
        { id: 4, link: null, testoLink: null, sourceName: 'privacy_4', testo: 'Acconsento al trattamento dei miei dati personali da parte di solaraccess.it o di terze parti nominate responsabili del trattamento per conto di solaraccess.it consistenti nell’elaborazione delle mie scelte di consumo e delle mie abitudini di acquisto, attraverso operazioni di trattamento tese alla profilazione dell’utente realizzate anche attraverso l’incrocio di dati raccolti in relazione a funzionalità diverse, per finalità di marketing, anche diretto, e per consentire l’invio di materiale informativo e/o pubblicitario di specifico interesse, con l’ausilio di strumenti automatizzati e non automatizzati.' }
    ];

    return (
        <Create resource="leads" title="Inserisci un nuovo Lead" mutationOptions={{ onSuccess }}>
            <SimpleForm defaultValues={{ creazione: moment().format("YYYY-MM-DD HH:mm:ss"), id_utente: idUtente }}>
                <Grid container>
                    <Grid item xs={12} xl={8}>
                        <Typography variant="h4">Inserisci un nuovo contatto</Typography>
                        <Alert severity="info" sx={{ my: "20px" }}>
                            <AlertTitle><b>NOTA BENE</b></AlertTitle>
                            <p>Ti informiamo che attualmente possiamo installare prodotti nelle seguenti città e provincia.<br />
                                <b>Sicilia</b>: Catania, Messina, Siracusa, Ragusa, Enna, Caltanissetta.<br />
                                <b>Marche</b>: Macerata, Ascoli Piceno, Fermo, Pesaro Urbino, Ancona.</p>
                            <p>Qualora il tuo contatto fosse in una città diversa da quelle indicate ci attiveremo per soddisfare la sua richiesta quanto prima, ma non possiamo garantire la tempestività dell’installazione.</p>
                        </Alert>
                    </Grid>
                </Grid>
                <Grid container columnSpacing={2} rowSpacing={0}>
                    <Grid item xs={12} xl={8}>
                        <TextInput fullWidth source="nominativo" label="Nominativo" type="text" validate={[required()]} />
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12} md={6} xl={4}>
                        <TextInput fullWidth source="indirizzo" label="Indirizzo" type="text" validate={[required()]} />
                    </Grid>
                    <Grid item xs={12} md={6} xl={4} spacing={1}>
                        <Grid container columnSpacing={2}>
                            <Grid item xs={6} sm={3}>
                                <TextInput source="n_civico" label="N. Civico" type="text" validate={[required()]} />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <TextInput source="cap" label="CAP" type="text" validate={[required()]} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectInput fullWidth source="provincia" choices={ListaProvince} optionText="name" optionValue="sigla" validate={[required()]} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12} md={6} xl={4}>
                        <TextInput fullWidth source="cod_fisc" label="Codice Fiscale" type="text" validate={[required(), verificaCF]} />
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                        <TextInput fullWidth source="telefono" label="Telefono" type="text" validate={[required()]} />
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12} md={6} xl={4}>
                        <TextInput fullWidth source="comune" label="Città" type="text" validate={[required()]} />
                    </Grid>
                    {/* <Grid item xs={12} md={6} xl={4}>
                        <TextInput fullWidth source="p_iva" label="Partita IVA" type="text" />
                    </Grid> */}
                    <Grid item xs={12} md={6} xl={4}>
                        <TextInput fullWidth source="email" label="E-Mail" type="email" validate={[required(), email()]} />
                    </Grid>
                    <Grid item xs={12} md={6} xl={8}>
                        <TextInput fullWidth source="note" label="Note" type="text" multiline sx={{ height: '100px' }} />
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12}>
                        {privacyCheck.map((opt, i) => (
                            <>
                                <CheckboxGroupInput fullWidth source={opt.sourceName} choices={[opt]} optionText='testo' validate={[required()]} sx={{ mb: 0 }} />
                                {opt.link !== null ? (<Button variant="outlined" sx={{ my: 0 }} label={opt.testoLink} onClick={() => { window.open(opt.link, '_blank') }} />) : null}
                            </>
                        ))}
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};