import React from 'react';
import { Edit, SelectInput, TextInput, required, useNotify, SimpleForm, Toolbar, SaveButton } from 'react-admin';
import { verificaCF, verificaIban, ListaProvince } from '../generale';
import { Grid, TextField, Box, Typography, Card, Button } from '@mui/material';

const MyToolbar = () => {
    const notify = useNotify();
    console.log("custom toolbar");
    return (
        <Toolbar>
            <SaveButton 
                label="Salva"
                mutationOptions={{
                    onSuccess: () => {
                        notify('Modifica salvata', { type: 'success' });
                    }}
                }
                type="button"
                variant="text"
            />
        </Toolbar>
    );
};

const ProfiloUtente = ({ staticContext, ...props }) => {

    React.useEffect(() => {
        document.title = "Il tuo profilo";
    }, []);

    return (
        <Edit resource='utenti' title={"Il tuo profilo personale"}>
            <SimpleForm toolbar={<MyToolbar />}>
                <Box sx={{ my: 0, pb: 2, pt:0 }}>
                    <Typography variant="h4" sx={{ my: 2 }}>Il tuo profilo</Typography>
                    {/* <Box sx={{ maxWidth: "unset", p: 3, mx: 0 }} component="div"> */}
                        <Grid container columnSpacing={2}>
                            <Grid item xs={12} md={4} xl={4}>
                                <TextInput source="nominativo" label="Nome e Cognome" type="text" validate={[required()]} fullWidth disabled />
                            </Grid>
                            <Grid item xs={12} md={4} xl={4}>
                            <TextInput source="cod_fisc" label="Codice Fiscale" type="text" validate={[required(), verificaCF]} fullWidth disabled />
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <TextInput source="telefono" label="Telefono" type="text" validate={[required()]} fullWidth />
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item xs={12} md={4} xl={4}>
                            <TextInput source="rag_soc" label="Ragione Sociale" type="text" fullWidth />
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <TextInput source="p_iva" label="Partita IVA" type="text" fullWidth />
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <TextInput source="sdi" label="Codice SDI" type="text" fullWidth />
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item xs={12} md={6} lg={3}>
                            <TextInput source="comune" label="Città" type="text" validate={[required()]} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <TextInput source="indirizzo" label="Indirizzo" type="text" validate={[required()]} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={12} lg={5}>
                            <Grid container columnSpacing={2}>
                                <Grid item xs={6} sm={3} md={3}>
                                    <TextInput fullWidth source="n_civico" label="N. Civico" type="text" validate={[required()]} />
                                </Grid>
                                <Grid item xs={6} sm={3} md={3}>
                                    <TextInput fullWidth source="cap" label="CAP" type="text" validate={[required()]} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <SelectInput fullWidth source="provincia" choices={ListaProvince} optionText="name" optionValue="sigla" validate={[required()]} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <TextInput source="iban" label="IBAN" type="text" fullWidth validate={[required(), verificaIban]} />
                        </Grid>
                        </Grid>
                    {/* </Box> */}
                </Box>
            </SimpleForm>
        </Edit>
    );
};

export default ProfiloUtente;