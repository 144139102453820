import * as React from "react";
import { List, ListBase, Datagrid, TextField, ReferenceField, EditButton, FunctionField, usePermissions, useRecordContext, useListContext, TextInput, ReferenceInput, AutocompleteInput, SimpleList, SelectInput } from 'react-admin';
import { Alert, Card, Typography, Divider, useMediaQuery } from '@mui/material';
import { Phone, LocationOn } from '@mui/icons-material';
import { RuotaDevice } from '../RuotaDevice';
import { LoadingComponent } from '../LoadingComponent';
import { EmptyPage } from '../EmptyPage';
import { ListaProvince } from '../generale';
import moment from 'moment';

export const LeadDetails = () => {
    const record = useRecordContext();
    // console.log(record);
    if (!record) return <LoadingComponent />;
    return (
        <>
            <Alert severity="info">{(record.note !== '' ? record.note : 'Non sono state inserite note aggiuntive')}</Alert>
            <p><b>Storico degli aggiornamenti di stato</b></p>
            <ListBase disableSyncWithLocation resource="leads_movimenti_stati" filter={{ id_lead: record.id }} exporter={false} perPage={100} sort={{ field: 'id', order: 'DESC' }} pagination={false} sx={{ mt: '-60px', mb: '40px' }}>
                <Datagrid bulkActionButtons={false} sx={{ backgroundColor: '#f1f1f1' }}>
                    <FunctionField render={record => `${moment(record.data_ora).format("DD/MM/YYYY HH:mm:ss")}`} label="Data/Ora" />
                    <ReferenceField source="id_stato" reference="leads_stati" label="Stato" link={false} sortable={false}>
                        <TextField source="descr" />
                    </ReferenceField>
                    <TextField source="note" />
                </Datagrid>
            </ListBase>
        </>
    );
}

export const LeadsList = () => {
    const { permissions } = usePermissions();
    const permFilter = (permissions !== '4' ? { id_utente: localStorage.getItem('auth_uid') } : false);
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const filtersAdmin = [
        <TextInput source="nominativo_cs" label="Cerca per nominativo" alwaysOn />,
        <ReferenceInput source="id_utente" reference="utenti" alwaysOn>
            <AutocompleteInput label="Segnalatore" optionText={record => record.nominativo !== null && record.nominativo !== '' ? record.nominativo : record.rag_soc} />
        </ReferenceInput>,
        <SelectInput fullWidth source="provincia" choices={ListaProvince} optionText="name" optionValue="sigla" alwaysOn />
    ];

    React.useEffect(() => {
        document.title = "Lista dei Leads";
    }, []);

    return (
        <>
            <RuotaDevice />
            {/* exporter={(permissions !== '4' ? false : '')} */}
            <List filter={permFilter} filters={(permissions === '4' ? filtersAdmin : null)} sort={{ field: 'id', order: 'DESC' }} perPage={50} exporter={false} empty={<EmptyPage testo='leads' />}>
                {isSmall ? (

                    <SimpleList
                        primaryText={record => <Typography variant="h5">{record.nominativo}</Typography>}
                        // secondaryText={record => `Tel. ${record.telefono}`}
                        secondaryText={
                            record =>
                                <>
                                    <Alert icon={<Phone />} severity="info" sx={{ my: 1, py: 0 }}>Tel. {record.telefono}
                                    </Alert>
                                    <Alert icon={<LocationOn />} severity="info" sx={{ my: 1, py: 0 }}>{`${record.indirizzo}, ${record.n_civico} - ${record.cap} ${record.comune} (${record.provincia})`}
                                    </Alert>
                                    <Alert severity="info" sx={{ my: 1, py: 0 }}>Stato:
                                        <ReferenceField source="id_movimento_stato" reference="leads_movimenti_stati" label="Stato" link={false} sortable={false} sortBy="id" sortByOrder="DESC">
                                            <ReferenceField source="id_stato" reference="leads_stati" label="Stato" link={false}>
                                                <TextField source="descr" sx={{ ml: 0.5 }} />
                                            </ReferenceField>
                                        </ReferenceField>
                                    </Alert>
                                    <Divider sx={{ mt: 4 }} />
                                </>
                        }
                    />
                ) : (
                    <Datagrid bulkActionButtons={false} rowClick="expand" expand={<LeadDetails />}>
                        {permissions === '4' ?
                            <TextField source="id" sortable={false} />
                            : null}
                        <TextField source="nominativo" sortable={false} />
                        <FunctionField sortable={false} render={record => (
                            (record.indirizzo !== null && record.indirizzo !== '' ? `${record.indirizzo},` : ``) +
                            (record.n_civico !== null && record.n_civico !== '' ? `${record.n_civico} - ` : ``) +
                            (record.cap !== null && record.cap !== '' ? `${record.cap}` : ``) + ' ' +
                            (record.comune !== null && record.comune !== '' ? `${record.comune}` : `-`) +
                            (record.provincia !== null && record.provincia !== '' ? ` (${record.provincia})` : ``)
                        )} label="Città" />
                        <TextField source="telefono" sortable={false} />
                        <TextField source="email" sortable={false} />
                        {permissions === '4' ?
                            <ReferenceField source="id_utente" reference="utenti" label="Segnalatore" link={false}>
                                <TextField source="nominativo" />
                            </ReferenceField>
                            : null}
                        <ReferenceField source="id_movimento_stato" reference="leads_movimenti_stati" label="Stato" link={false} sortable={false} sortBy="id" sortByOrder="DESC">
                            <ReferenceField source="id_stato" reference="leads_stati" label="Stato" link={false}>
                                <TextField source="descr" />
                            </ReferenceField>
                        </ReferenceField>
                        {permissions === '4' ? <EditButton label="Modifica stato"></EditButton> : null}
                    </Datagrid>
                )}
            </List>
        </>
    )
};