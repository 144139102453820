import * as React from "react";
import { List, ListBase, Datagrid, TextField, ReferenceField, Button, EditButton, FunctionField, usePermissions, useRecordContext, useListContext, Confirm, ReferenceInput, AutocompleteInput, SimpleList, useUpdate, useGetList, useCreate, useNotify } from 'react-admin';
import { Alert, Card, Typography, Divider, useMediaQuery } from '@mui/material';
import { RuotaDevice } from '../RuotaDevice';
import { LoadingComponent } from '../LoadingComponent';
import moment from 'moment';
var numeral = require('numeral');

export const LeadDetails = () => {
    const record = useRecordContext();
    if (!record) return <LoadingComponent />;
    // console.log(record);
    return (
        <>
            <p><b>Leads inclusi nel pagamento</b></p>
            <ListBase disableSyncWithLocation resource="leads_pagati_dettaglio" filter={{ id_utente: record.id_utente }} exporter={false} perPage={100} sort={{ field: 'data_ora', order: 'DESC' }} pagination={false} sx={{ mt: '-60px', mb: '40px' }} sortable={false}>
                <Datagrid bulkActionButtons={false} sx={{ backgroundColor: '#f1f1f1' }}>
                    <TextField source="id" />
                    <ReferenceField source="id" reference="leads" label="Nominativo" link={false}>
                        <TextField source="nominativo" />
                    </ReferenceField>
                    <FunctionField sortable={false} render={record => (`${numeral(parseFloat(record.valore)).value()} €`)} label="Valore" />
                    <FunctionField render={record => `${moment(record.data_ora).format("DD/MM/YYYY HH:mm:ss")}`} label="Pagamento effettuato il" />
                </Datagrid>
            </ListBase>
        </>
    );
}

export const ListaLeadsPagati = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const { permissions } = usePermissions();

    React.useEffect(() => {
        document.title = "Lista dei Leads pagati";
    }, []);

    return (
        <>
            <RuotaDevice />
            <List sort={{ field: 'id', order: 'DESC' }} perPage={50} exporter={false}>
                <Datagrid bulkActionButtons={false} expand={<LeadDetails />}>
                    <TextField source="id" label="ID utente" />
                    <ReferenceField source="id_utente" reference="utenti" label="Nominativo segnalatore" link={false}>
                        <TextField source="nominativo" />
                    </ReferenceField>
                    <ReferenceField source="id" reference="utenti" label="Telefono" link={false} sortable={false}>
                        <TextField source="telefono" />
                    </ReferenceField>
                    <ReferenceField source="id" reference="utenti" label="Email" link={false} sortable={false}>
                        <TextField source="email" />
                    </ReferenceField>
                    <FunctionField sortable={false} render={record => (`${numeral(parseFloat(record.totale)).value()} €`)} label="Totale" />
                </Datagrid>
            </List>
        </>
    )
};