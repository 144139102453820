import * as React from "react";
import { Create, SimpleForm, useNotify, useRedirect, useUpdate, TextInput, SelectInput, useGetOne, useGetList, useReference, required } from 'react-admin';
import { useParams } from "react-router-dom";
import { useQuery } from 'react-query';
import { Grid, Alert, AlertTitle, Typography } from '@mui/material';
import { dataProvider } from '../generale';
import moment from 'moment';

export const LeadModifica = () => {
    const id_utente = localStorage.getItem('auth_uid');
    const { id } = useParams();
    const notify = useNotify();
    const redirect = useRedirect();
    const [update] = useUpdate();
    const [dettaglioLead, setDettaglioLead] = React.useState(null);
    const [loadingDettaglioLead, setLoadingDettaglioLead] = React.useState(true);
    const [dettaglioUtente, setDettaglioUtente] = React.useState();
    const [loadingDettaglioUtente, setLoadingDettaglioUtente] = React.useState(true);
    const [conteggiUtente, setConteggiUtente] = React.useState();

    const { data: listaStatiLeads, isLoading, error } = useGetList(
        "leads_stati", {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'descr', order: 'ASC' }
    });

    const getLeadsData = async () => {
        await dataProvider.getOne(
            "leads",
            { id }
        ).then(({ data }) => {
            setDettaglioLead(data);
            setLoadingDettaglioLead(false);
            // console.log("ID segnalatore: " + data.id_utente);
        });
    };

    const getUserData = async () => {
        await dataProvider.getOne(
            "utenti",
            { id: dettaglioLead.id_utente }
        ).then(({ data }) => {
            setDettaglioUtente(data);
            setLoadingDettaglioUtente(false);
            // console.log("ID ruolo segnalatore: " + data.id_ruolo);
        });
    };

    const getUserCount = (lead) => {
        if (lead.id_utente !== '') {
            // console.log("ID segnalatore: " + lead.id_utente);
            dataProvider.getList(
                "leads_conteggi", {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'id', order: 'DESC' },
                filter: { id_utente: lead.id_utente }
            }
            ).then(({ data }) => {
                // console.log(data[0].conteggio);
                if (data.length > 0)
                    setConteggiUtente(parseInt(data[0].conteggio));
                else
                    setConteggiUtente(0);
            });
        }
    };

    React.useEffect(() => {
        getLeadsData();
    }, []);

    React.useEffect(() => {
        if (dettaglioLead && !loadingDettaglioLead) {
            getUserData();
            // console.log("ID segnalatore: " + dettaglioLead.id_utente);
            getUserCount(dettaglioLead);
        }
    }, [dettaglioLead]);

    // React.useEffect(() => {
    //     if (dettaglioUtente && !loadingDettaglioUtente) {
    //         console.log("ID ruolo segnalatore: " + dettaglioUtente.id_ruolo);
    //     }
    // }, [dettaglioUtente]);


    const ListaStatiLeads = () => {
        if (isLoading) { return <p>Loading</p>; }
        if (error) { return <p>ERROR</p>; }
        return <SelectInput source="id_stato" label="Stato" choices={listaStatiLeads} optionText="descr" optionValue="id" validate={[required()]} />;
    };

    const TitoloSezione = () => {
        if (isLoading) { return <p>Loading</p>; }
        if (error) { return <p>ERROR</p>; }
        return <span>Modifica lo stato del lead: <b>{dettaglioLead ? `"${dettaglioLead.nominativo} (ID: ${id})"` : ''}</b></span>;
    };

    const onSuccess = async ({ id: idMS, id_stato }) => {
        // console.log(idMS, id_stato);
        await getUserCount(dettaglioLead);
        // console.log("conteggiUtente: " + conteggiUtente, "id_ruolo: " + dettaglioUtente.id_ruolo, "id_stato: " + id_stato);
        // console.log("valore: " + (conteggiUtente < 5 ? 100 : 150));
        let valoreLead;
        switch (id_stato) {
            case 1:
            case 4:
                valoreLead = null;
                break;
            case 2:
                valoreLead = 100;
                // valoreLead = (dettaglioUtente.id_ruolo === 1 && conteggiUtente < 5 ? 100 : 150);
                break;
            case 3:
                valoreLead = 0;
                break;
            default:
                break;
        }
        let id_ms;
        // console.log("valoreLead: " + valoreLead);
        if (valoreLead !== null) {
            id_ms = {
                id_movimento_stato: idMS,
                valore: valoreLead
            };
        } else {
            id_ms = {
                id_movimento_stato: idMS
            };
        }

        if (dettaglioUtente.id_ruolo === 1 && conteggiUtente > 10) {
            await update(
                'utenti',
                {
                    id: dettaglioUtente.id,
                    data: {
                        richiesta_cs : 1
                    }
                }, {
                onError: (error) => {
                    notify("Si è verificato un errore con l'aggiornamento di stato. Riprova più tardi. Se il problema persiste contatta la nostra Assistenza Tecnica.", { type: 'warning' });
                    redirect("/leads");
                }
            })
        }

        await update(
            'leads',
            {
                id: id,
                data: id_ms
            }, {
            onSuccess: (data) => {
            // onSettled: () => {
                notify(`Stato aggiornato correttamente`, { type: 'success' });
                redirect(`/leads`);
            },
            onError: (error) => {
                notify("Si è verificato un errore con l'aggiornamento di stato. Riprova più tardi. Se il problema persiste contatta la nostra Assistenza Tecnica.", { type: 'warning' });
                redirect("/leads");
            }
        })
    };

    React.useEffect(() => {
        document.title = "Modifica lo stato del lead";
    }, []);

    if (isLoading || loadingDettaglioLead) return null;

    return (
        <Create resource="leads_movimenti_stati" redirect="leads" title={<TitoloSezione />} mutationOptions={{ onSuccess }}>
            <SimpleForm defaultValues={{ data_ora: moment().format("YYYY-MM-DD HH:mm:ss"), id_operatore: parseInt(id_utente), id_lead: parseInt(id) }}>
                <Typography variant="h4">Modifica lo stato del lead: {dettaglioLead.nominativo}</Typography>
                <ListaStatiLeads />
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <TextInput source="note" fullWidth multiline />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Alert severity="info">
                            <AlertTitle><b>NOTA BENE</b></AlertTitle>
                            <p>Eventuali note inserite saranno visibili a tutti.</p>
                        </Alert>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Create>
    )
};