// in src/App.js
import * as React from "react";
import { createElement } from 'react';
import { Admin, Resource, Link, defaultTheme, Login, CustomRoutes, Menu, MenuItemLink, Layout, useResourceDefinitions, usePermissions, Authenticated } from 'react-admin';
import { Grid, Card, CardActionArea, CardMedia, CardContent, CardHeader } from '@mui/material';
import { dataProvider, i18nProvider } from "./components/generale.js";
import authProvider from './authProvider';
import { Route } from "react-router-dom";

// import jsonServerProvider from 'ra-data-json-server';

// Utenti
import { UtentiList } from './components/Utenti/UtentiLista';
import { UtenteNuovo } from './components/Utenti/UtenteNuovo';
import { UtenteModifica } from './components/Utenti/UtenteModifica';
// Leads
import { LeadsList } from './components/Leads/LeadsLista';
import { LeadNuovo } from './components/Leads/LeadNuovo';
import { LeadModifica } from './components/Leads/LeadModifica';
import { LeadsReteDirettaList } from './components/Leads/LeadsReteDirettaLista';
// FAQ
import { FaqListAdmin, FaqListUser } from './components/Faq/FaqLista';
import { FaqNuovo } from './components/Faq/FaqNuovo';
import { FaqModifica } from './components/Faq/FaqModifica';
// Engagement
import { Engagement } from './components/Engagement';
// Profilo
import ProfiloUtente from './components/Profilo/ProfiloUtente';
// Profilo
import { ListaLeadsPagabili } from './components/Commerciale/LeadsPagabili';
import { ListaLeadsPagati } from './components/Commerciale/LeadsPagati';
// Reset Password
import ProfiloEdit from './components/ResetPassword';
// Login
import MyLoginPage from './Login';
// Tasto WhatsApp
// import { TastoWhatsapp } from './components/TastoWhatsapp';

// Icone
import { ListAlt, People, HelpOutline, EuroSymbol, WhatsApp, PermIdentity, RequestQuote, Paid, CheckCircleOutline, PauseCircleOutline } from '@mui/icons-material';

// const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');

const myTheme = {
  ...defaultTheme,
  palette: {
    primary: "#002249",
    secondary: "#E0C166",
    error: "#d9534f",
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
  },
};

const Dashboard = () => {
  const { permissions } = usePermissions();

  React.useEffect(() => {
    document.title = "Solar Access - Area Riservata";
  }, []);

  return (
    <Grid container spacing={2} sx={{ my: "10px" }}>
      {permissions !== '5' ?
        <>
          <Grid item xs={12} sm={6} xl={3}>
            <Link to="/leads/create">
              <Card sx={{ maxWidth: "unset" }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    src={require('./includes/img/lead-new.jpg')}
                  />
                  <CardHeader title="Nuovo Lead" sx={{ pb: "0" }} />
                  <CardContent>Inserisci un nuovo lead.</CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} xl={3}>
            <Link to="/engagement">
              <Card sx={{ maxWidth: "unset" }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    src={require('./includes/img/engagement.jpg')}
                  />
                  <CardHeader title="Engagement" sx={{ pb: "0" }} />
                  <CardContent>La tua carriera su SolarAccess</CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} xl={3}>
            <Link to="/faq">
              <Card sx={{ maxWidth: "unset" }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    src={require('./includes/img/faq.jpg')}
                  />
                  <CardHeader title="FAQ" sx={{ pb: "0" }} />
                  <CardContent>Domande e risposte in caso di dubbi</CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} xl={3}>
            <Link to="#" onClick={() => window.location.replace("https://wa.me/393332851942")}>
              <Card sx={{ maxWidth: "unset" }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    src={require('./includes/img/assistenza.jpg')}
                  />
                  <CardHeader title="Assistenza" sx={{ pb: "0" }} />
                  <CardContent>Ricevi supporto dal nostro team tecnico/commerciale</CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
        </>
        :
        <>
          <Grid item xs={12} sm={6} xl={3}>
            <Link to="/leads_pagabili_riepilogo">
              <Card sx={{ maxWidth: "unset" }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={require('./includes/img/leads-pagabili.jpg')}
                  />
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, height: '140px', width: '100%', backgroundColor: 'rgba(0,0,0,0.5)' }}><PauseCircleOutline sx={{ color: 'white', fontSize: '50px' }} /></div>
                  <CardHeader title="Leads pagabili" sx={{ pb: "0" }} />
                  <CardContent>Lista dei leads che possono essere pagati</CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} xl={3}>
            <Link to="/leads_pagati_riepilogo">
              <Card sx={{ maxWidth: "unset" }}>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={require('./includes/img/leads-pagabili.jpg')}
                  />
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, height: '140px', width: '100%', backgroundColor: 'rgba(0,0,0,0.5)' }}><CheckCircleOutline sx={{ color: 'white', fontSize: '50px' }} /></div>
                  <CardHeader title="Leads pagati" sx={{ pb: "0" }} />
                  <CardContent>Lista dei leads già pagati</CardContent>
                </CardActionArea>
              </Card>
            </Link>
          </Grid>
        </>
      }
    </Grid>
  )
};

export const MyMenu = (props) => (
  <Menu {...props}>
  </Menu>
);

const App = () => {

  const CustomMenu = () => {
    const userID = localStorage.getItem('auth_uid');
    const resources = useResourceDefinitions();
    const { permissions } = usePermissions();
    // if ((resources).length <= 0) return null;

    return (
      <Menu>
        <Menu.DashboardItem />
        {permissions !== '4' && permissions !== '5'
          ?
          <div>
            <Menu.Item key="utenti" to={`/utenti/${userID}`} primaryText="Profilo personale" leftIcon={<PermIdentity />} />
            <Menu.Item key="engagement" to="/engagement" primaryText="Engagement" leftIcon={<EuroSymbol />} />
          </div>
          : null
        }

        {Object.keys(resources).map(name => (
          (resources[name].icon !== undefined ?
            <Menu.Item
              key={name}
              to={`/${name}`}
              primaryText={resources[name].options.label || name}
              leftIcon={createElement(resources[name].icon)}
            />
            : null
          )
        ))}
        {/* backgroundColor:"#DCF8C6" per whatsapp */}
        {permissions !== '4' && permissions !== '5'
          ? <Menu.Item key="assistenza" to="#" primaryText="Assistenza" leftIcon={<WhatsApp sx={{ color: "#25D366" }} />} onClick={() => window.location.replace("https://wa.me/393332851942")} />
          : null}
      </Menu>
    );
  }

  const CustomLayout = (props) => {
    return (
      <Layout {...props} menu={CustomMenu} />
    );
  }

  React.useEffect(() => {
    document.title = "Solar Access - Area Riservata";
  }, []);

  return (
    < Admin dataProvider={dataProvider} dashboard={Dashboard} authProvider={authProvider} loginPage={MyLoginPage} i18nProvider={i18nProvider} layout={CustomLayout} >
      {/* <Admin dataProvider={dataProvider} dashboard={Dashboard} authProvider={authProvider} theme={myTheme} loginPage={LoginPage} requireAuth> */}
      {permissions => (
        <>
          {permissions !== '5' ?
            <Resource name="leads" options={{ label: permissions !== '4' ? 'I tuoi leads' : 'Leads' }} icon={ListAlt} list={LeadsList} create={permissions !== '4' ? LeadNuovo : null} edit={permissions === '4' ? LeadModifica : null} />
            : null}
          {permissions === '4'
            ? <Resource name="utenti" options={{ label: 'Utenti' }} icon={People} list={UtentiList} create={UtenteNuovo} edit={UtenteModifica} />
            : null}
          <CustomRoutes>
            <Route path="/engagement" element={<Engagement />} icon={EuroSymbol} />
          </CustomRoutes>
          <CustomRoutes noLayout>
            <Route path="/reset-password" element={<ProfiloEdit />} />
          </CustomRoutes>
          <Resource name="leads_movimenti_stati" />
          {permissions !== '5' && permissions !== '4'
            ? <Resource name="utenti" edit={<ProfiloUtente />} />
            : null}
          {permissions !== '5'
            ? <Resource name="faq" options={{ label: 'F.A.Q.' }} icon={HelpOutline} list={permissions === '4' ? FaqListAdmin : FaqListUser} create={permissions === '4' ? FaqNuovo : null} edit={permissions === '4' ? FaqModifica : null} />
            : null}
          {permissions === '5'
            ? <>
              <Resource name="leads_pagabili_riepilogo" options={{ label: 'Leads Pagabili' }} icon={RequestQuote} list={ListaLeadsPagabili} />
              <Resource name="leads_pagabili_dettaglio" />
              <Resource name="leads_pagati_riepilogo" options={{ label: 'Leads Pagati' }} icon={Paid} list={ListaLeadsPagati} />
              <Resource name="leads_pagati_dettaglio" />
              {/* <Resource name="utenti" options={{ label: 'Utenti' }} icon={People} edit={UtenteModifica} /> */}
            </>
            : null}
          {permissions === '1_rd' ?
            <Resource name="leads_rete_diretta" options={{ label: 'Leads Rete Diretta' }} icon={ListAlt} list={LeadsReteDirettaList} />
          : null}
        </>
      )}
    </Admin >
  )
};

export default App;
